import React from 'react';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import 'rc-slider/assets/index.css';
import 'react-dates/initialize';

import '../BookingEngine/components/styles/datePicker.css';
import {
  IPageDetails,
  IPageContext,
  ICurrencyOption,
  IReducers,
  ITourListItem,
  IPageTours,
  IPageWysiwyg,
} from '../interfaces';
import { TitleArea, TextParser, TourList } from '../components';
import withMeta from '../hocs/withMeta';
import { ProductApi } from '../api';
import { findDefaultPrice, getPageElement } from '../utils';

interface IProps {
  data: {
    contentfulPage: IPageDetails;
  };
  pageContext: IPageContext;
  currency: ICurrencyOption;
  lang: string;
}

interface IState {
  tours: ITourListItem[];
  loading: boolean;
}

class ToursNoFilter extends React.Component<IProps, IState> {
  readonly state = { tours: [], loading: false };

  productApi = new ProductApi();

  componentDidMount() {
    const { pageElements } = this.props.data.contentfulPage;
    const tourElems = getPageElement(
      pageElements,
      'ContentfulPageTours'
    ) as IPageTours;
    const tourList = !!tourElems && !!tourElems.tours ? tourElems.tours : [];
    const tours = tourList.map((tour) => ({
      contentfulTour: tour,
      product: null,
    }));
    this.setState({ tours });
    this.getDefaultPrices();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.currency !== this.props.currency) {
      this.getDefaultPrices();
    }
  }

  render() {
    const { pageElements, title, carousel } = this.props.data.contentfulPage;
    const { tours, loading } = this.state;
    const about = getPageElement(
      pageElements,
      'ContentfulPageWysiwyg'
    ) as IPageWysiwyg;

    return (
      <div>
        <TitleArea title={title}>
          {about && <TextParser source={about.text.text} />}
        </TitleArea>
        <div className='centered-content vertical-margin'>
          <p>Test 5</p>
          <TourList
            tours={tours}
            loading={loading}
            langPath={this.props.pageContext.langPath}
            currency={this.props.currency}
            carousel={carousel}
          />
        </div>
      </div>
    );
  }

  getDefaultPrices = async () => {
    this.setState({ loading: true });
    const { lang } = this.props;
    const { pageElements } = this.props.data.contentfulPage;
    const tourElems = getPageElement(
      pageElements,
      'ContentfulPageTours'
    ) as IPageTours;
    const tourList = tourElems ? tourElems.tours : [];
    if (!!tourElems && !!tourElems.tours) {
      const defaultPrices = await this.productApi.getDefaultPrices(
        tourList.map((tour) => tour.productId),
        this.props.currency.value,
        lang
      );

      if (defaultPrices) {
        const tours = tourList.map((tour) => ({
          contentfulTour: tour,
          product: findDefaultPrice(defaultPrices, tour.productId),
        }));
        this.setState({ tours });
      }
    }
    this.setState({ loading: false });
  };
}

export const query = graphql`
  query ToursNoFilterQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageDetails
    }
  }
`;

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { lang } = reducers.translationReducers;
  return { currency, lang };
};

export default connect(mapStateToProps)(withMeta(ToursNoFilter));
